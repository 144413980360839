import  React  from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import App from "./App.js";


Providers.globalProvider = new Msal2Provider({
  clientId: process.env.REACT_APP_GRAPH_ID,
  authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_GRAPH_TENANT,
  scopes: ['user.read']
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

