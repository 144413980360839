import {React, useState, useEffect} from "react";
import Login from "./components/Login";
import SignatureGenerator from "./components/SignatureGenerator";

function App () {
    const [user, setUser] = useState(null);
    useEffect(() => {
        document.title = "eSkillz Signature Generator"
    });

    if(user === null) {
        return <>
            <Login setUser={setUser}/>
        </>
    }
    else{
        return <>
        <SignatureGenerator user={user}/> 
    </>
    }
}

export default App;