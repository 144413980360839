import { Providers } from "@microsoft/mgt-element";
import { Login } from '@microsoft/mgt-react';


function LogIn ({setUser}) {

    return <>
        <Login
        loginCompleted={(e) => {
            Providers.globalProvider.graph.client.api('me').version('beta').get()
            .then(gotMe => {
                setUser(gotMe)
            });
        }}
        logoutCompleted={(e) => { }}
        />
    </>
}


export default LogIn;